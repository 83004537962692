import React, {useState, useEffect} from 'react';
import {Col, Row, Toast, ToastBody, Button} from 'reactstrap';
import { NavLink } from 'react-router-dom';

const DynamicToast = () => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const dismissed = localStorage.getItem('bannerDismissed');
        if (dismissed) {
            setVisible(false);
        }
    }, []);

    const dismiss = () => {
        setVisible(false);
        localStorage.setItem('bannerDismissed', 'true');
    };

    return (
        <>
            {visible && (
                <Toast style={{
                    bottom: "1rem",
                    width: "calc(100% - 1.25rem)",
                    right: ".625rem",
                    maxWidth: "430px",
                    zIndex: 20
                }} className="position-fixed text-bg-secondary text-light">
                    <ToastBody>
                        <Row>
                            <Col xs={12}>
                                <p className="mb-3"><strong>Störung: 24.03. (17:50 Uhr) – 25.03. (7:30 Uhr)</strong></p>
                                <p>Es wurden keine SMS versendet. Für alle in diesem Zeitraum eingegebenen Mobilnummern wurde die Code-Anforderung wieder aktiviert.</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                <Button className="w-100" tag={NavLink} to="/code-anfordern" size="sm" color="light" onClick={dismiss}>Jetzt Code anfordern</Button>
                            </Col>
                            <Col xs={6}>
                                <Button className="w-100" size="sm" color="light" outline onClick={dismiss}>Schließen</Button>
                            </Col>
                        </Row>
                    </ToastBody>

                </Toast>
            )}
        </>
    );
};

export default DynamicToast;